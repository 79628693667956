import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h1>Deutschrap Quiz</h1>
          <p>Whoopla! Um weiterzufahren, musst du diesen Link in der App öffnen.</p>
      </header>
    </div>
  );
}

export default App;
